.btn {
  margin-left: 5px;
}

.disabled {
  input {
    background-color: #ccc;
    pointer-events: none;
  }
}

.user {
  padding: 5px;
}
